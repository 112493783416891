import {Box, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import isEmailValid from '../../utils/EmailValidator';

interface TextInputProps {
    label: string;
    required: boolean;
    isEmail?: boolean;
    isNumber?: boolean;
    editMode: boolean;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
}

export default function TextInput(props: TextInputProps) {
    const {t} = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value as string);
    };

    const isNumber = (value: string): boolean => {
        return value !== null && !isNaN(Number(value)) && value.trim() !== '';
    };

    const isError = (): boolean => {
        return ((props.required && props.value === '')
            || (props.isEmail && !validEmail)
            || (props.isNumber && !validNumber)) ? true : false;
    };

    const getHelperText = (): string => {
        if (props.required && props.value === '') {
            return props.label + ' ' + t('form.required');
        }

        if (props.isEmail && props.value !== '' && !isEmailValid(props.value)) {
            return t('form.valid_email_required');
        }

        if (props.isNumber && props.value !== '' && !isNumber(props.value)) {
            return t('form.valid_number_required');
        }

        return '';
    };

    let validEmail = isEmailValid(props.value);
    let validNumber = isNumber(props.value);

    return (
        <Box sx={{paddingBottom: "20px"}}>
            <Typography variant="smallTitle">
                {props.label} {props.required ? "*" : ""}
            </Typography>
            <TextField
                id={props.label + "-text-input"}
                size="small"
                placeholder={props.placeholder}
                variant="outlined"
                error={isError()}
                helperText={getHelperText()}
                color="info"
                disabled={!props.editMode}
                value={props.value}
                type={props.isNumber ? "number" : "text"}
                onChange={handleChange}
                sx={{
                    width: "100%"
                }}
            />
        </Box>
    );
};





