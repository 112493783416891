import {Box, Button, Typography} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from "react-i18next";
import { colors, getStatusColors } from "../../../themes/AppTheme";
import { useContext } from "react";
import { UserPreferencesContext } from "../../../providers/UserPreferencesProvider";
import CircleIcon from '@mui/icons-material/Circle';
import ReactGA from "react-ga4";

interface TimelineToolbarProps {
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
}

export default function TimelineToolbar(props: TimelineToolbarProps) {

    const { colorPalette } = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);
    const { t } = useTranslation();

    return (
        <Box sx={{
            height: '50px',
            width: '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        }}>
            <Button
            variant="outlined"
            color="primary"
            size='small'
            sx={{
                marginRight: '10px',
                display: {md: 'none', lg: 'block'},
            }}
            onClick={() => {
                ReactGA.event("RT2-Timeline-PreviousDay");
                props.setSelectedDate(props.selectedDate.subtract(1, 'day'))
            }}>
                -1 Day
            </Button>
            <DatePicker
                sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: colors.ram_purple,
                }}
                disableFuture
                label={t('timeline.datepicker_label')}
                value={props.selectedDate}
                format="DD/MM/YYYY"
                slotProps={{ textField: { size: 'small' } }}
                onChange={(newDate) => {
                    if (newDate != null) {
                        ReactGA.event("RT2-Timeline-SelectDate");
                        props.setSelectedDate(newDate)
                    } else {
                        props.setSelectedDate(dayjs().startOf('date'))
                    }
                }}
            />
            <Button
                variant="outlined"
                color="primary"
                size='small'
                sx={{
                    marginLeft: '10px',
                    display: {md: 'none', lg: 'block'},
                }}
                onClick={() => {
                    ReactGA.event("RT2-Timeline-NextDay");
                    props.setSelectedDate(props.selectedDate.add(1, 'day'))
                }}
                disabled={props.selectedDate.isSame(dayjs(), 'day')}
            >
                +1 Day
            </Button>
            <Box sx={{ marginLeft: 'auto' }}></Box>

            <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>
                <CircleIcon sx={{ color: statusColors.moving, paddingRight: '2px' }} fontSize="small" />
                <Typography variant="caption">{t("asset_status.moving")}</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>
                <CircleIcon sx={{ color: statusColors.stationary, paddingRight: '2px' }} fontSize="small" />
                <Typography variant="caption">{t("asset_status.stationary")}</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>
                <CircleIcon sx={{ color: 'lightgrey', paddingRight: '2px' }} fontSize="small" />
                <Typography variant="caption">{t("asset_status.stopped")}</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>
                <CircleIcon sx={{ color: statusColors.private, paddingRight: '2px' }} fontSize="small" />
                <Typography variant="caption">{t("asset_status.private")}</Typography>
            </Box>
        </Box >
    );
}