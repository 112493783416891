import {Box, Button, Divider, Grid, InputAdornment, Paper, TextField, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../../utils/AssetArraySorting";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import { filterAssetsBySearch } from "../../../utils/AssetArrayFiltering";
import { useScheduledReportsApi } from "../../../hooks/scheduledReport/ScheduledReports";
import { FileFormat } from "../../../enum/FileFormat";
import TextInput from "../../../components/form/TextInput";
import ReportTypeSelection from "../shared/ReportTypeSelection";
import { ReportType } from "../../../enum/ReportType";
import ReportPeriodSelection from "../shared/ReportPeriodSelection";
import { ReportPeriod } from "../../../enum/ReportPeriod";
import { ReportDeliveryDay } from "../../../enum/ReportDeliveryDay";
import ReportDeliveryDaySelection from "../shared/ReportDeliveryDaySelection";
import ReportDaysCoveredSelection from "../shared/ReportDaysCoveredSelection";
import { ReportDaysCovered } from "../../../enum/ReportDaysCovered";
import ReportFormatSelection from "../shared/ReportFormatSelection";
import { SummariseBySelector } from "../shared/SummariseBySelector";
import { SummariseIncludeSelector } from "../shared/SummariseIncludeSelector";
import { SummariseAMPMSelector } from "../shared/SummariseAMPMSelector";
import { ReportDaysOfMonths } from "../../../enum/ReportDaysOfMonths";
import ReportDayOfMonthSelection from "../shared/ReportDayOfMonthSelection";
import { ScheduledReportRequest } from "../../../hooks/scheduledReport/dto/ScheduledReportRequest";
import { SummarisedReportOptions } from "../shared/SummarisedReportOptions";
import {SelectorLabel} from "../shared/SelectorLabel";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";

export default function ScheduledVehicleReportsEditorPage() {
    const theme = useTheme();
    const {t} = useTranslation();

    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedScheduledReport !== null && location.state.selectedScheduledReport !== undefined;
    const selectedScheduledReport = isEdit ? location.state.selectedScheduledReport : undefined;

    const upperDeliveryDay = isEdit ? selectedScheduledReport.deliveryDay.toUpperCase() : ReportDeliveryDay.MONDAY;



    const [name, setName] = useState<string>(isEdit ? selectedScheduledReport.name : '');
    const [email1, setEmail1] = useState<string>(isEdit ? selectedScheduledReport?.recipients[0] : '')
    const [email2, setEmail2] = useState<string>(isEdit ? selectedScheduledReport?.recipients[1] : '')
    const [email3, setEmail3] = useState<string>(isEdit ? selectedScheduledReport?.recipients[2] : '')

    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    
    const [selectedReportType, setSelectedReportType] = useState(isEdit ? selectedScheduledReport.reportType : ReportType.TRAVEL_REPORT);
    const [selectedReportPeriod, setSelectedReportPeriod] = useState(isEdit ? selectedScheduledReport.period : ReportPeriod.WEEKLY);
    const [selectedReportDeliveryDay, setSelectedReportDeliveryDay] = useState(upperDeliveryDay);
    const [selectedReportDaysCovered, setSelectedReportDaysCovered] = useState(isEdit ? selectedScheduledReport?.daysCovered : ReportDaysCovered.ONE_DAY);
    const [selectedReportDaysOfMonth, setSelectedReportDaysOfMonth] = useState(isEdit ? selectedScheduledReport?.daysCovered : ReportDaysOfMonths.FIRST_DAY);
    const [selectedReportFormat, setSelectedReportFormat] = useState(isEdit ? selectedScheduledReport.reportFormat : FileFormat.PDF);

    const [selectedByWeek, setSelectedByWeek] = useState(isEdit ? selectedScheduledReport.options.summariseBy === "WEEK" : false);
    const [selectedByMonth, setSelectedByMonth] = useState(isEdit ? selectedScheduledReport.options.summariseBy === "MONTH" : false);
    const [selectedByVehicle, setSelectedByVehicle] = useState(isEdit ? selectedScheduledReport.options.summariseByVehicle : false);
    const [selectedByDriver, setSelectedByDriver] = useState(isEdit ? selectedScheduledReport.options.summariseByDriver : false);
    const [selectedIncludeFullReport, setSelectedIncludeFullReport] = useState(isEdit ? selectedScheduledReport.options.includeFullReport : false);
    const [selectedIncludeSummaryTotals, setSelectedIncludeSummaryTotals] = useState(isEdit ? selectedScheduledReport.options.includeSummaryTotals : false);
    const [selectedSummariseAMPM, setSelectedSummariseAMPM] = useState(isEdit ? selectedScheduledReport.splitReport : false);

    const {sort} = useContext(FilterSortContext);

    const {createScheduledReport, updateScheduledReport} = useScheduledReportsApi({shouldLoadAll: true})

    const {assets, getAllAssets, loading: loadingAssets} = useAssetApi({shouldLoadAll:true});
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    } 

    const btnCreateScheduledReport = async () => {
        let deliveryDayValue: string = selectedReportPeriod === ReportPeriod.MONTHLY ? "" : selectedReportDeliveryDay;
        let daysCoveredValue: number = selectedReportPeriod === ReportPeriod.MONTHLY ? selectedReportDaysOfMonth : selectedReportDaysCovered;
        let optionsValue: SummarisedReportOptions | null = selectedReportType === ReportType.TRAVEL_SUMMARY_REPORT ? getSummarisedReportOptions() : null;

        await createScheduledReport(new ScheduledReportRequest({
            id: 1,
            name: name,
            reportType: selectedReportType,
            vehicleIds: selectedRows,
            deliveryDay: deliveryDayValue,
            daysCovered: daysCoveredValue,
            emailAddress1: email1,
            emailAddress2: email2,
            emailAddress3: email3,
            splitReport: selectedSummariseAMPM,
            options: optionsValue,
            period: selectedReportPeriod,
            tabId: 0,
            reportFormat: selectedReportFormat
        }))
            .then((scheduledReportsAlerts) => {
                navigate('/reports/scheduled-reports')
            })
            .catch((error) => {
                alert(t('scheduled_report.creation_failed'))
            })
    }

    const btnUpdateScheduledReport = async () => {
        let deliveryDayValue: string = selectedReportPeriod === ReportPeriod.MONTHLY ? "" : selectedReportDeliveryDay;
        let daysCoveredValue: number = selectedReportPeriod === ReportPeriod.MONTHLY ? selectedReportDaysOfMonth : selectedReportDaysCovered;
        let optionsValue: SummarisedReportOptions | null = selectedReportType === ReportType.TRAVEL_SUMMARY_REPORT ? getSummarisedReportOptions() : null;

        await updateScheduledReport(new ScheduledReportRequest({
            id: selectedScheduledReport.id,
            name: name,
            reportType: selectedReportType,
            vehicleIds: selectedRows,
            deliveryDay: deliveryDayValue,
            daysCovered: daysCoveredValue,
            emailAddress1: email1,
            emailAddress2: email2,
            emailAddress3: email3,
            splitReport: selectedSummariseAMPM,
            options: optionsValue,
            period: selectedReportPeriod,
            tabId: 0,
            reportFormat: selectedReportFormat
        }))
            .then((scheduledReportsAlerts) => {
                navigate('/reports/scheduled-reports')
            })
            .catch((error) => {
                alert(t('scheduled_report.update_failed'))
            })
    }


    const handleCancel = () => {
        navigate('/reports/scheduled-reports')
    }

    function getSummarisedReportOptions(): SummarisedReportOptions | null {
        let options: SummarisedReportOptions | null = null;
        if (selectedReportType === ReportType.TRAVEL_SUMMARY_REPORT) {
            options = {
                "@class": "com.ramtracking.domain.report.options.SummarisedReportOptions",
                summariseBy: (selectedByWeek || selectedByMonth) ? (selectedByWeek ? "WEEK" : "MONTH") : undefined,
                summariseByVehicle: selectedByVehicle,
                summariseByDriver: selectedByDriver,
                includeFullReport: selectedIncludeFullReport,
                includeSummaryTotals: selectedIncludeSummaryTotals,
            };
        }
        return options;
    }

    React.useEffect(() => {
        async function getAssets() {

            let assetList = await getAllAssets() as Asset[];

            if (assetList) {
                let selectedRowsList = assetList.filter(asset => {
                    return selectedScheduledReport.vehicles.includes(asset.identificationMark);
                }).map(asset => asset.id);

                setSelectedRows(selectedRowsList ? selectedRowsList : [])
            }
        }
        if(isEdit) {
            getAssets()
        }

    }, []);
   

    return (
        <Box
            component="main"
            sx={{
                // padding: '10px',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Name Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.name")}
                        subtitle={t("scheduled_report.enter_name")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={false}
                                   label={''} required={false} placeholder={''}
                                   value={name} onChange={setName}/>
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Report Type Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.report_type")}
                        subtitle={t("scheduled_report.enter_report_type")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <ReportTypeSelection selectedReportType={selectedReportType} setSelectedReportType={setSelectedReportType} />
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {(selectedReportType === ReportType.PRIVATE_REPORT ||
                    selectedReportType === ReportType.PRIVATE_REPORT_FINANCE ||
                    selectedReportType === ReportType.PRIVATE_REPORT_DISTANCE_ONLY) && (
                <>
                {/* Summarise AM/PM Selection */}
                    <SummariseAMPMSelector
                    selectedSummariseAMPM={selectedSummariseAMPM}
                    setSelectedSummariseAMPM={setSelectedSummariseAMPM}
                    />

                    <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
                </>
                
                )}

                {selectedReportType === ReportType.TRAVEL_SUMMARY_REPORT && (
                <>
                {/* Summarise By Selection */}
                    <SummariseBySelector
                    selectedByWeek={selectedByWeek}
                    setSelectedByWeek={setSelectedByWeek}
                    selectedByMonth={selectedByMonth}
                    setSelectedByMonth={setSelectedByMonth}
                    selectedByVehicle={selectedByVehicle}
                    setSelectedByVehicle={setSelectedByVehicle}
                    selectedByDriver={selectedByDriver}
                    setSelectedByDriver={setSelectedByDriver}
                    />

                    <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                    {/* Include Selection */}
                    <SummariseIncludeSelector
                    selectedIncludeFullReport={selectedIncludeFullReport}
                    setSelectedIncludeFullReport={setSelectedIncludeFullReport}
                    selectedIncludeSummaryTotals={selectedIncludeSummaryTotals}
                    setSelectedIncludeSummaryTotals={setSelectedIncludeSummaryTotals}
                    />

                    <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
                </>
                
                )}

                {/* Asset Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.asset_selection")}
                        subtitle={t("scheduled_report.asset_selection_message")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("scheduled_report.asset_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={assetFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectAssetTable
                                multiselect={true}
                                loading={loadingAssets}
                                assets={filterAssets(assets || [])}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Report Period Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.report_period")}
                        subtitle={t("scheduled_report.select_report_period")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <ReportPeriodSelection selectedReportPeriod={selectedReportPeriod} setSelectedReportPeriod={setSelectedReportPeriod} />
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {selectedReportPeriod === ReportPeriod.WEEKLY && (
                    <React.Fragment>
                        {/* Report Delivery Day Selection */}
                        <Grid container>
                            <SelectorLabel
                                title={t("scheduled_report.delivery_day")}
                                subtitle={t("scheduled_report.select_delivery_day")}
                            />
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                                <ReportDeliveryDaySelection selectedReportDeliveryDay={selectedReportDeliveryDay} setSelectedReportDeliveryDay={setSelectedReportDeliveryDay} />
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
                    </React.Fragment>
                )}

                {selectedReportPeriod === ReportPeriod.WEEKLY && (
                    <>
                        {/* Report Days Covered Selection */}
                        <Grid container>
                            <SelectorLabel
                                title={t("scheduled_report.days_covered")}
                                subtitle={t("scheduled_report.select_days_covered")}
                            />
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                            <ReportDaysCoveredSelection
                                selectedReportDaysCovered={selectedReportDaysCovered}
                                setSelectedReportDaysCovered={setSelectedReportDaysCovered}
                            />
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
                    </>
                )}

                {selectedReportPeriod === ReportPeriod.MONTHLY && (
                    <>
                        {/* Report Days Of Month Selection */}
                        <Grid container>
                            <SelectorLabel
                                title={t("scheduled_report.days_of_month")}
                                subtitle={t("scheduled_report.select_days_of_month")}
                            />
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                            <ReportDayOfMonthSelection
                                selectedReportDaysOfMonth={selectedReportDaysOfMonth}
                                setSelectedReportDaysOfMonth={setSelectedReportDaysOfMonth}
                            />
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
                    </>
                )}

                {/* Report Format Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.report_format")}
                        subtitle={t("scheduled_report.select_report_format")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <ReportFormatSelection selectedReportFormat={selectedReportFormat} setSelectedReportFormat={setSelectedReportFormat} />
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Email 1 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.email_1")}
                        subtitle={t("scheduled_report.enter_email_1")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email1} onChange={setEmail1}/>
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Email 2 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.email_2")}
                        subtitle={t("scheduled_report.enter_email_2")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email2} onChange={setEmail2}/>
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Email 3 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("scheduled_report.email_3")}
                        subtitle={t("scheduled_report.enter_email_3")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email3} onChange={setEmail3}/>
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedRows.length === 0 || (email1 === '' && email2 === '' && email3 === '')}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? btnUpdateScheduledReport : btnCreateScheduledReport}
                            >
                                { isEdit ? t("button.edit") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
