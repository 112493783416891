import {useContext} from "react"
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce"
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";

const usePlatformFetch = () => {

    const auth: IAuthContext = useContext(AuthContext)
    const {impersonateId} = useContext(ImpersonateUserContext)

    const platformFetch = async (url: string, method: string, body?: string) => {
        return await fetch(url, {
            method: method,
            headers: buildHeaders(body !== undefined),
            body: body,
        })
    }

    const buildHeaders = (bodyPresent: Boolean) => {
        let headers = new Headers({
            Authorization: "Bearer " + auth.token,
        })

        if (bodyPresent) {
            headers.append("Content-Type", "application/json")
        }

        if (impersonateId) {
            headers.append("x-impersonate-id", impersonateId.toString())
        }

        return headers
    }

    const checkOk = (response: Response) => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    const checkCreated = (response: Response) => {
        if (response.status === 201) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    return {platformFetch, checkOk, checkCreated}

}

export default usePlatformFetch
