import {FeaturePackage} from "../hooks/account/Account";


export enum Feature {
    ROUTE_REPLAY,
    TIMELINE,
    ALERTS,
    REPORTS,
    LIVESHARE,
    APP
}

interface FeatureRestriction {
    feature: Feature;
    packages: FeaturePackage[];
}

const featureRestrictions: FeatureRestriction[] = [
    {
        feature: Feature.ROUTE_REPLAY,
        packages: [FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_FULL]
    },
    {
        feature: Feature.TIMELINE,
        packages: [FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_FULL]
    },
    {
        feature: Feature.ALERTS,
        packages: [FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_FULL]
    },
    {
        feature: Feature.REPORTS,
        packages: [FeaturePackage.LITE, FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_LITE, FeaturePackage.LEGACY_FULL]
    },
    {
        feature: Feature.LIVESHARE,
        packages: [FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_FULL]
    },
    {
        feature: Feature.APP,
        packages: [FeaturePackage.LITE, FeaturePackage.CORE, FeaturePackage.PLUS, FeaturePackage.LEGACY_LITE, FeaturePackage.LEGACY_FULL]
    },
];



export function isFeatureInPackage(feature: Feature, featurePackage: FeaturePackage): boolean {
    return featureRestrictions.some((restriction) => restriction.feature === feature && restriction.packages.includes(featurePackage));
}