import AlertsContentPanel from "./shared/AlertsContentPanel";
import React from "react";
import SecondaryNavigationPanel from "../../components/secondary_navigation_panel/SecondaryNavigationPanel";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SplitView from "../../layouts/SplitView";
import propertiesLoader from "../../properties/PropertiesLoader";
const properties = propertiesLoader();
interface AlertsPageProps {
}

function AlertsPage(props: AlertsPageProps) {
    const { t } = useTranslation();
    const { pathname } = useLocation()


    const secondaryNavItems = [
        { text: t("alert_navigation_panel.geofence_alerts"), selected: pathname === '/alerts' || pathname === '/alerts/geofence-alerts', to: 'geofence-alerts' },
        { text: t("alert_navigation_panel.distance_alerts"), selected: pathname === '/alerts/distance-alerts', to: 'distance-alerts' },
        { text: t("alert_navigation_panel.event_alerts"), selected: pathname === '/alerts/event-alerts', to: 'event-alerts' }
    ]

    if(properties.featureFlags.fuelAlerts) {
        secondaryNavItems.push({ text: t("alert_navigation_panel.fuel_alerts"), selected: pathname === '/alerts/fuel-alerts', to: 'fuel-alerts' })
    }

    if(properties.featureFlags.wasteAlerts) {
        secondaryNavItems.push({ text: t("alert_navigation_panel.waste_alerts"), selected: pathname === '/alerts/waste-alerts', to: 'waste-alerts' })
    }


    const secondaryNav = <SecondaryNavigationPanel title={t("alert_navigation_panel.title") as String} items={secondaryNavItems} />

    const content = <AlertsContentPanel />

    return (
        <SplitView scondaryNavigation={secondaryNav} content={content} />
    );
}

export default React.memo(AlertsPage, (prevProps, nextProps) => true);
